<script>
import axios from 'axios'
import OnboardingCard from '../../OnboardingCard.vue'
import OnboardingHeader from '../../OnboardingHeader.vue'
import OnboardingHeading from '../../OnboardingHeading.vue'

export default {
  name: 'ForgotPassword',

  data() {
    return {
      email: '',
      emailSent: false,
      errors: [],
      loading: false,
      errorStatus: null,
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Not a valid email address'
        },
        required: value => !!value || 'This field is Required',
      },
      valid: false,
    }
  },

  components: {
    OnboardingCard,
    OnboardingHeader,
    OnboardingHeading
  },

  methods: {
    storeNotFound() {
      let status = (this.errorStatus !== 422 || this.isStatusOk === null) || 'Account not found, try another email'
      let a = this.$refs.field
      if(a) {
        a.focus()
      }
      return status
    },

    async resetPasswordHandler() {
      try {
        this.loading = true
        const response = await axios.post('forgot-password', { email: this.email })
        if(response.data.success) this.emailSent = true
        this.loading = false
        const { success, errors } = response.data
        if(!success) {
          this.errors = []
          this.errors.push(errors[0])
        }
      } catch(error) {
        this.errors = []
        this.errorStatus = error.status
        this.storeNotFound()
        this.loading = false
      }
    }
  }
}
</script>

<template>
  <section class="onboarding v2">
    <OnboardingHeader />
    <OnboardingHeading heading="Reset my passsword" />
    <p class="onboarding-text">
      Enter your Syncio account's email to reset your password <br> We'll send you an email with a link and instructions
    </p>

    <v-alert v-for="(value, key) in errors" v-bind:key="key" class="error rounded text-capitalize" :class="{ 'm-t-4': errors }" transition="fade-transition" border="left" style="width: 650px; margin-left: auto; margin-right: auto;" dismissible>
      <svg class="mr-5" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.99996 0.666626C4.39996 0.666626 0.666626 4.39996 0.666626 8.99996C0.666626 13.6 4.39996 17.3333 8.99996 17.3333C13.6 17.3333 17.3333 13.6 17.3333 8.99996C17.3333 4.39996 13.6 0.666626 8.99996 0.666626ZM8.99996 9.83329C8.54163 9.83329 8.16663 9.45829 8.16663 8.99996V5.66663C8.16663 5.20829 8.54163 4.83329 8.99996 4.83329C9.45829 4.83329 9.83329 5.20829 9.83329 5.66663V8.99996C9.83329 9.45829 9.45829 9.83329 8.99996 9.83329ZM9.83329 13.1666H8.16663V11.5H9.83329V13.1666Z" fill="#FF0000"/>
      </svg>
      <span>{{ value }}</span>
    </v-alert>

    <template v-if="!emailSent">
      <OnboardingCard>
        <v-form v-model="valid">
          <v-text-field
            :rules="[rules.required, rules.email, storeNotFound()]"
            class="onboarding-input"
            dense
            label="Email address"
            outlined
            type="email"
            v-model="email"
            @input="errorStatus = null"
            ref="field" />

          <v-btn
            :disabled="!valid"
            :loading="loading"
            @click="resetPasswordHandler()"
            block
            class="onboarding-btn m-t-2"
            color="primary"
            elevation="0">
            Reset Password
          </v-btn>
        </v-form>
      </OnboardingCard>

      <p class="onboarding-text">
        If you still need help, contact <a href="mailto:support@syncio.co" class="btn-link">support@syncio.co</a>
      </p>
    </template>

    <OnboardingCard v-else>
      <aside class="onboarding-success-message">
        <p class="onboarding-text text-center m-y-0">
          Email sent to {{ email }} <br />
          Check your inbox shortly for instructions
        </p>
      </aside>
    </OnboardingCard>

    <div class="text-center m-t-7">
      <router-link to="/login" class="btn-plain">
        Go back
      </router-link>
    </div>
  </section>
</template>
